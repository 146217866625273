import React from 'react'
import Parser from 'html-react-parser';

import { GetInContact } from "../components/getInContact";
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Partners } from "../components/Partners";
import { HeroText } from '../components/HeroText';
import { graphql, Link } from "gatsby";
import { Testimonial } from "../components/Testimonial";

import imageSchwanthaler from "../images/schwanthaler.jpg";

const ReferenzenPage = ({ data }) => {

  function createHandleKeyDownCaseStudy(url) {
    return e => {
      if (e.keyCode === 13) {
        window.location = url;
      }
    };
  }

  function createHandleClickCaseStudy(url) {
    return () => window.location = url;
  }

  return (
    <Layout activeMenuItem={'referenzen'}>

      <Seo
        title="Usability"
        keywords={[`design`, `strategisches design`, `ux`, `user experience`]}
      />

      <HeroText>Mit strategischem Design schaffen wir ein optimales Erlebnis für die Kunden unserer
        Geschäftspartner.</HeroText>

      <div className="content-wrapper">
        <div className="content">
          <div className="case-study-previews">
            {data.allStrapiCasestudy.edges.map(edge =>
              <div
                className="case-study-preview"
                role="button"
                tabIndex="0"
                onClick={createHandleClickCaseStudy(`/case-studies/${edge.node.slug}`)}
                onKeyPress={createHandleKeyDownCaseStudy(`/case-studies/${edge.node.slug}`)}
              >
                <div className={'case-study-preview__image'}>
                  {edge.node.companyLogo &&
                  <img src={edge.node.companyLogo.localFile.publicURL} alt={`Logo: ${Parser(edge.node.companyName)}`} />}
                </div>
                <div className={'case-study-preview__content'}>
                  <h2>{Parser(edge.node.companyName)}</h2>
                  <p><Link tabIndex={-1} to={`/case-studies/${edge.node.slug}`} title="Zur Case-Study">{Parser(edge.node.title)}</Link></p>
                </div>
              </div>
            )}
          </div>

          <Testimonial
            style={{ padding: 'calc(3rem + 3vw) 0 calc(2rem + 3vw) 0' }}
            image={imageSchwanthaler}
            imageAlt={'Mag. Manfred Schwanthaler'}
            cite={<>Mag. Manfred Schwanthaler, Business Development am <a href="https://www.scch.at">SCCH</a></>}
          >
            Das Beste an Simplease war, dass sie unsere komplette Produkt-Strategie hinterfragt und an die
            Ziele unserer Kunden angepasst haben.
          </Testimonial>

          <Partners style={{ marginBottom: '1rem' }} />

        </div>
      </div>

      <GetInContact />

    </Layout>
  )
};

export default ReferenzenPage

export const query = graphql`
  {
    allStrapiCasestudy(filter:{online: {eq:true}}) {
      edges {
        node {
          id
          slug
          title
          companyName
          companyLogo {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;
