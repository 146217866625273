import React from "react";
import * as styles from './styles.module.css'

export function Testimonial({ children, cite, image, imageAlt, style }) {
  return <div className={styles.testimonial} style={style}>
    <div className={styles.imageWrapper}>
      <img src={image} className={styles.image} alt={imageAlt} />
    </div>
    <div className={styles.quoteWrapper}>
      <blockquote>„{children}“
      </blockquote>
      <cite>{cite}</cite>
    </div>
  </div>;
}